import React from "react";

import NewsArticle from "./NewsArticle";
import ArticleLoading from "./ArticleLoading";
import AlertError from "components/Alerts/AlertError";
import NotFound from "contents/NotFound";
import { useFetch } from "libs/api";

type Props = {
  id: number;
  className?: string;
};
export default function ServiceArticle({ id, className }: Props) {
  if (!id) return <NotFound />;

  const url = `${process.env.REACT_APP_API_PATH_ARTICLE}?id=${id}`;
  const { data: article, isLoading, error, noData } = useFetch(url);

  if (isLoading || !article) return <ArticleLoading />;

  if (error)
    return (
      <div className="flex flex-wrap">
        <div className="w-full p-3">
          <AlertError message={`Could not load the article #${id}`} />
        </div>
      </div>
    );

  if (noData) return <NotFound />;

  if (typeof article[0] !== "undefined")
    return <NewsArticle article={article[0]} className={className} />;
  else return <NewsArticle article={article} className={className} />;
}
